import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import {
  AppService, LaunchDarklyService, MarketingOrder, ProductInstance, UserService,
} from '@lc/core';
import {
  AvailableSurvey, ScopingData, Survey, SurveyClient, SurveyForm,
} from '../clients';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private surveyClient: SurveyClient) { }

  async getSurvey(id: string) {
    return await firstValueFrom(this.surveyClient.getById$(id));
  }

  async addOrUpdateSurvey(form: SurveyForm) {
    const fn = form.isNew
      ? this.surveyClient.create(form)
      : this.surveyClient.update(form.id, form);
    return await fn;
  }

  async toggleSurveyActive(survey: Survey, active: boolean): Promise<Survey> {
    const form = new SurveyForm(survey);
    form.getControl('active').setValue(active);
    form.getControl('active').markAsDirty();
    return await this.surveyClient.patch(survey._id, form);
  }

  async query() {
    return await firstValueFrom(this.surveyClient.query$());
  }

  /**
   * Returns a list of surveys available for this scoping data:
   *
   * HACK: For now, we are leveraging launch darkly to return us the
   * available surveys based on scoping criteria. Later we can build our own
   */
  async getAvailableSurveys(scoping: ScopingData): Promise<AvailableSurvey[]> {
    return await firstValueFrom(this.surveyClient.queryAvailable$(scoping));
  }
}

export interface LCSurveyOptions {
  pageName: 'Dashboard' | 'Order Management' | 'Product Viewer';
  order?: MarketingOrder;
  product?: ProductInstance;
}

/**
 * NOTE: Extract to its own area. This should not be in @re-surveys, but due to circular dependency issues, we had to put it here
 */
@Injectable({
  providedIn: 'root',
})
export class LCSurveyService {
  constructor(private surveyService: SurveyService, private launchDarkley: LaunchDarklyService, private userService: UserService) {
  }

  async getAvailableSurveys(options: LCSurveyOptions) {
    const isEnabled = await this.launchDarkley.getFeature('in-app-surveys', true);
    if (!isEnabled) {
      console.debug('Surveys are disabled. Returning empty array');
      return [];
    }

    const scoping = await this.buildScoping(options);
    return await this.surveyService.getAvailableSurveys(scoping);
  }

  private async buildScoping(options: LCSurveyOptions) {
    const surveyGroup = await this.launchDarkley.getFeature<string>('survey-group', 'default');
    const user = this.userService.getCurrentUser();
    const office = user?.profile?.office;

    const scoping: ScopingData = {
      // Featureflag level scopes
      surveyGroup,

      // App level scopes
      app: AppService.app, // 'admin', 'agent', 'coordinator', etc...
      appSuite: AppService.productSuiteName, // 'Listing Concierge', 'Design Concierge'

      // Page level scopes
      pageName: options?.pageName,

      // User level scopes
      userId: user._id,
      userEmail: user.email,
      userRoles: user.roles || [],

      // Office level scopes
      officeArea: office?.marketingArea,
      officeName: office?.address?.name,
      officeBrandCode: office?.company?.brandCode,
    };

    const { order, product } = options;

    if (order) {
      // Order level scopes
      scoping.orderId = order._id;
      scoping.orderArea = order.area || '';
      scoping.orderStatus = order.status;

      if (order.selectedPackage) {
        // Package level scopes
        scoping.packageCode = order?.selectedPackage?.code;
      }
    }

    if (product) {
      // Product level scopes
      scoping.productCode = product.code;
      scoping.productCategory = product.category;
      scoping.productKind = product.kind;
      scoping.productStatus = product.status;
      scoping.productIsVendorPrinted = product.isVendorPrinted;
    }

    return scoping;
  }
}
