<lc-card *ngIf="form" [formGroup]="form" [loading]="spinner" [header]="header">
  <ng-template #content>
    <div class="form-section">
      <lc-checkbox class="mb-1" label="Active" formControlName="active"></lc-checkbox>

      <lc-textbox label="Survey Title" [required]="true" formControlName="title"
                  (blur)="setSurveyKey()"></lc-textbox>

      <lc-textbox label="Survey Key" hint="Used as an identifier in API calls" formControlName="key"
                  [readonly]="!form.isNew"></lc-textbox>

      <lc-textbox formControlName="description" label="Survey Description"></lc-textbox>
    </div>

    <lc-chips label="Distinct Fields" size="sm" hint="What fields make this survey unique?"
              placeholder="ex: 'userId', 'orderId'" formControlName="distinctOn"
              [allowCustomInput]="true"></lc-chips>

    <lc-form-array [form]="form.get('scopes')" label="Scoping" hint="Add or remove scopes">

      <ng-template #view let-scopeForm="form">
        <div *ngIf="scopeForm?.value as scope">
          <div class="mat-body-2 text-bold line-height-0">
            {{scope.title }}
          </div>

          <div class="mat-caption line-height-0 fx-column">
            <ng-container *ngFor="let item of scope.filters | keyvalue; let last = last">
              <span>
                <b>{{item.key}}</b>
                <ng-container *ngIf="item.value as filter">
                  {{filter.operator}} {{filter.value}}
                </ng-container>
              </span>
            </ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #edit let-scopeForm="form">
        <!-- {{(scopeForm.value || {unknown: true}) | json}} -->

        <ng-container [formGroup]="scopeForm">
          <lc-textbox formControlName="title" label="Scope Title"
                      hint="The descrition of this scope"
                      placeholder="ex: 'California Users'"></lc-textbox>

          <!-- Filters -->
          <lc-form-array [form]="scopeForm.filtersArray" label="Filters"
                         hint="Add or remove filters">
            <ng-template #view let-filterForm="form">
              <lc-header *ngIf="filterForm?.value as filter" [header]="filter.key"
                         subheader="{{filter.operator}} {{filter.value}}"></lc-header>
            </ng-template>

            <ng-template #edit let-filterForm="form">

              <div class="py-1" [formGroup]="filterForm">
                <lc-textbox formControlName="key" label="Field Name" hint="They key of the field"
                            placeholder="ex: marketingArea"></lc-textbox>

                <lc-dropdown [formControl]="filterForm.valueType" label="Field Type"
                             placeholder="ex: String, Boolean, Number"
                             [options]="filterForm.valueTypes"></lc-dropdown>


                <lc-dropdown formControlName="operator" label="Operator"
                             placeholder="ex: 'equals', 'not-equals', etc."
                             [options]="filterForm.operators$ | async"></lc-dropdown>


                <ng-container [ngSwitch]="filterForm.valueType$ | async">
                  <!-- Boolean Values -->
                  <lc-checkbox *ngSwitchCase="'Boolean'" label="Value"
                               formControlName="value"></lc-checkbox>

                  <!-- Number Values-->
                  <lc-textbox *ngSwitchCase="'Number'" formControlName="value" label="Value"
                              placeholder="i.e. 10.00" [inputMask]="MASKS.decimalWithWholeNumber"
                              [maskDropSpecialCharacters]="false"></lc-textbox>

                  <!-- String Values-->
                  <ng-container *ngSwitchDefault>
                    <ng-container [ngSwitch]="filterForm.isList$ | async">
                      <lc-chips *ngSwitchCase="true" formControlName="value" label="value"
                                hint="They values this field contains"
                                placeholder="ex: ['value1', 'value2']"
                                [allowCustomInput]="true"></lc-chips>
                      <lc-textbox *ngSwitchDefault formControlName="value" label="value"
                                  hint="They value of the field"
                                  placeholder="ex: value1"></lc-textbox>
                    </ng-container>
                  </ng-container>

                </ng-container>


              </div>
            </ng-template>
          </lc-form-array>

        </ng-container>
      </ng-template>
    </lc-form-array>


    <div class="form-section" [formGroup]="form.dynamicForm">
      <div class="mat-body-2">Survey Form</div>

      <mat-form-field class="form-array" appearance="outline" floatLabel="always"
                      class="w-100 small">
        <mat-label>Questions:</mat-label>

        <!-- Hidden matInput plugs into formControl -->
        <input matInput class="d-none" [errorStateMatcher]="matcher">

        <mat-list class="height-auto h-100 p-0 fx-column">

          <!-- List of all uploaded files-->
          <ng-container *ngFor="let kvp of form.dynamicForm.inputs.controls | keyvalue">
            <ng-container *ngIf="kvp.value as childForm">

              <mat-list-item lcHovered class="border-bottom py-1 font-size-inherit"
                             #listItem="hovered">

                <div *ngIf="childForm.value as input" class="w-100 fx-row y-center fx-gap-sm" [style.min-height.px]="40">
                  <lc-header [header]="input.label" [subheader]="input.inputType"></lc-header>


                  <div class="fx-1-1-0">
                    <mat-icon *ngIf="childForm?.dirty" color="primary"
                              matTooltip="Unsaved changes">published_with_changes</mat-icon>
                  </div>

                  <!-- Delete Button: displays when hovered -->
                  <ng-container *ngIf="listItem.isHovered">
                    <button mat-icon-button>
                      <mat-icon class="material-icons-outlined" (click)="onEdit(childForm)">
                        edit
                      </mat-icon>
                    </button>

                    <button mat-icon-button>
                      <mat-icon class="material-icons-outlined" (click)="onDelete(childForm)">
                        delete
                      </mat-icon>
                    </button>
                  </ng-container>


                </div>
              </mat-list-item>
            </ng-container>
          </ng-container>

          <!-- Add Button -->
          <mat-list-item class="p-0 fx-row x-end">
            <button mat-icon-button (click)="onAdd()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>

        <mat-error *ngIf="formControl && formControl.invalid" [innerHTML]="errors"
                   class="error-message">
        </mat-error>
      </mat-form-field>


      <!-- <pre>{{form.value | json}}</pre> -->
    </div>
  </ng-template>

  <ng-template #actions>
    <lc-form-buttons (save)="onSave()" (cancel)="onCancel()"></lc-form-buttons>
  </ng-template>
</lc-card>