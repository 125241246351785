import { Injectable } from '@angular/core';
import { ApiService } from '@lc/core';
import { Observable } from 'rxjs';
import { SurveyStatistics } from './survey-statistics.model';

const ROUTES = {
  getById: (id: string) => `surveys/${id}/statistics`,
};

@Injectable({
  providedIn: 'root',
})
export class SurveyStatisticsClient {
  constructor(private apiService: ApiService) { }

  getById$(surveyId: string): Observable<SurveyStatistics> {
    const url = ROUTES.getById(surveyId);
    return this.apiService.find$(url, { version: 'v2', model: SurveyStatistics });
  }
}
