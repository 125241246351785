import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER,
} from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';

import { InputsModule } from '@lc/ui';
import { PreloadAllModules, RouterModule } from '@angular/router';

import {
  CoreModule,
  StateModule,
  StartupService,
  AppService,
  initializeApp,
  LaunchDarklyService,
  CannyService,
  DataDogLogger,
} from '@lc/core';
import { SurveyResponseFormModule } from '@re-surveys/ui-ng';
import { UserIdleModule, UserIdleConfig } from 'angular-user-idle';
import { ngxLoadingAnimationTypes, NgxLoadingComponent, NgxLoadingModule } from 'ngx-loading';
import { SharedComponentsUiModule } from '@lc/shared-components-ui';
import { AppComponent } from './app.component';
import '@abt-desk/rsc/lib/es2015';
import '@abt-desk/rsc/lib/es2015/footer';
import '@abt-desk/rsc/lib/es2015/header';
import '@abt-desk/rsc/lib/es2015/speed-dial';
import { ServiceNowChatComponent } from './service-now-chat/service-now-chat.component';

if (location.hostname !== 'localhost' && location.hostname !== 'remotedev') {
  DataDogLogger.initialize('pub7f8f31a15154517d03fd6111b8de6851');
  DataDogLogger.initializeDDRealUserMonitoring('pub7f8f31a15154517d03fd6111b8de6851');
}

@NgModule({
  declarations: [AppComponent, ServiceNowChatComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DragDropModule,
    InputsModule,
    SharedComponentsUiModule.forRoot(),
    SurveyResponseFormModule,
    RouterModule.forRoot(
      [{ path: '', loadChildren: () => import('@lc/lc-app-ui').then((m) => m.LcAppUiModule) }],
      {
        enableTracing: false,
        paramsInheritanceStrategy: 'always',
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
      },
    ),
    CoreModule.forRoot(),
    StateModule,

    UserIdleModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.0)',
      backdropBorderRadius: '4px',
      primaryColour: '#1f69ff',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff',
    }),
  ],
  exports: [NgxLoadingComponent, InputsModule],
  providers: [
    {
      provide: APP_INITIALIZER, useFactory: initializeApp, deps: [StartupService], multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    // User activity timeouts
    // Ping value is not used
    // Session timeout is pulled from AppSettings after load.  If not found default is 5 minutes
    {
      provide: UserIdleConfig,
      useFactory: () => ({ idle: AppService.get('sessionTimeout') * 60, timeout: 30, ping: 10000 }),
    },
    LaunchDarklyService,
    CannyService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
