import { Component, OnInit } from '@angular/core';
import { ToasterService } from '@lc/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  animate, state, style, transition, trigger,
} from '@angular/animations';
import { Observable } from 'rxjs';
import {
  Survey, SurveyResponse, SurveyStatistics, SurveyStatisticsClient,
} from '../../clients';
import { SurveyService } from '../../services';

export class ViewModel {
  constructor(readonly survey: Survey, readonly statistics$: Observable<SurveyStatistics>) { }
}
@Component({
  selector: 'lc-survey-response-list',
  templateUrl: './survey-response-list.component.html',
  styleUrls: ['./survey-response-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SurveyResponseListComponent implements OnInit {
  readonly data = new MatTableDataSource<ViewModel>();
  readonly columns = ['title', 'key', 'actions'];
  isAllExpanded: boolean = false;
  expandedElement?: Survey | null;

  surveyDetail$!: Observable<SurveyResponse[]>;

  constructor(
    private surveyService: SurveyService,
    private statisticsService: SurveyStatisticsClient,
    private toaster: ToasterService,
  ) { }

  ngOnInit(): void {
    this.getSurveys();
  }

  async getSurveys() {
    try {
      const surveys = await this.surveyService.query();
      this.data.data = (surveys ?? []).map((survey) => {
        const statistics$ = this.statisticsService.getById$(survey._id);
        return new ViewModel(survey, statistics$);
      });
    } catch (error) {
      console.error(error);
      this.toaster.showError('There was a problem when trying to fetch survey responses.');
    }
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  isEmptyObject(obj: {}) {
    return !Object.keys(obj).length;
  }

  async setExpandedRow(rowData: Survey) {
    this.expandedElement = this.expandedElement === rowData ? null : rowData;
  }
}
