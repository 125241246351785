import {
  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { PromptDialogService } from '@lc/core';
import { InputForm, LoadingEvent, Masks } from '@lc/ui';
import { SurveyService } from '../../services';
import { SurveyInputFieldComponent } from './survey-input-field';
import { Survey, SurveyForm } from '../../clients';

@Component({
  selector: 'lc-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss'],
})
export class SurveyFormComponent implements OnChanges {
  readonly form = new SurveyForm();
  readonly spinner = LoadingEvent.none;
  readonly MASKS = Masks;
  @Input() id?: string;

  @Output() readonly submitted = new EventEmitter<Survey>();
  @Output() readonly cancelled = new EventEmitter();

  header: string = '';

  constructor(private surveyService: SurveyService, private dialogService: PromptDialogService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.id) {
      this.resetForm();
    }
  }

  async onSave() {
    if (this.form.invalid) {
      return this.form.markAllAsDirty();
    }
    this.spinner?.start('Saving...');
    await this.surveyService.addOrUpdateSurvey(this.form)
      .then((survey) => {
        this.submitted.emit(survey);
        // TODO: Toaster
      })
      .catch((error) => {
        // TODO: Toaster
      })
      .finally(() => {
        this.spinner.done();
      });
  }

  async onCancel() {
    this.cancelled.emit();
  }

  async setSurveyKey() {
    if (!this.form.isNew) { return; }

    const title = this.form.get('title')?.value;
    if (title) {
      const key = title.trim().split(' ').join('-').toLowerCase();
      this.form.get('key')?.setValue(key);
    }
  }

  async onAdd() {
    const response = await SurveyInputFieldComponent.open(this.dialogService, { });
    if (response) {
      this.form.dynamicForm.addInput(response);
    }
  }

  async onEdit(form: InputForm) {
    const response = await SurveyInputFieldComponent.open(this.dialogService, { input: form.originalValue as any });
    if (response) {
      this.form.dynamicForm.updateInput(response);
    }
  }

  async onDelete(form: InputForm) {
    this.form.dynamicForm.deleteInput(form.get('key')?.value);
  }

  private async resetForm() {
    const survey = this.id
      ? await this.surveyService.getSurvey(this.id)
      : new Survey();
    this.form.reset(survey);
    this.header = `${this.form.isNew ? 'Add' : 'Edit'} Survey`;
  }
}
