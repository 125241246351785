import { BaseForm, LCFormArray, arrayLengthValidator } from '@lc/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicForm } from '@lc/ui';
import {
  Observable, combineLatest, map, shareReplay, startWith,
} from 'rxjs';
import {
  OperatorType, OperatorTypes, Scope, ScopeFilter, Survey,
} from './survey.model';

type ValueType = 'String' | 'Boolean' | 'Number';

export class ScopeFilterForm extends BaseForm<ScopeFilter & { key: string }> {
  readonly valueType: FormControl<ValueType>;
  readonly operators$: Observable<OperatorType[]>;
  readonly isList$: Observable<boolean>;
  readonly valueType$: Observable<ValueType>;
  readonly valueTypes: ValueType[] = ['String', 'Boolean', 'Number'];
  constructor(model?: Partial<ScopeFilter & { key: string }>) {
    super({
      key: new FormControl(model?.key, [Validators.required]),
      operator: new FormControl(model?.operator, [Validators.required]),
      value: new FormControl(model?.value, [Validators.required]),
    });
    this.isList$ = this.getControl('operator').valueChanges.pipe(
      startWith(model?.operator),
      map(() => {
        const { value } = this.getControl('value');
        const operator = this.getControl('operator').value as OperatorType;

        const listTypes: OperatorType[] = ['in', 'not-in'];
        const isList = listTypes.includes(operator);
        if (isList) {
          if (!(value instanceof Array)) {
            this.getControl('value').setValue([value]);
          }
        } else if (value instanceof Array) {
          this.getControl('value').setValue(value[0]);
        }

        return isList;
      }),
      shareReplay(1),
    );
    const valueType = this.getValueType(model?.value);
    this.valueType = new FormControl<ValueType>(valueType) as FormControl<ValueType>;
    this.valueType$ = this.valueType.valueChanges.pipe(
      startWith(valueType),
      map((type) => {
        const control = this.getControl('value');
        if (type === 'String' && typeof model?.value !== 'string') {
          control.setValue(control.value ? `${control.value}` : null);
          control.markAsDirty();
        } else if (type === 'Number' && typeof model?.value !== 'number') {
          control.setValue(control.value ? +control.value : null);
          control.markAsDirty();
        } else if (type === 'Boolean' && typeof model?.value !== 'boolean') {
          control.setValue(!!control.value);
          control.markAsDirty();
        }
        return type;
      }),
      shareReplay(1),
    );

    this.operators$ = this.valueType.valueChanges.pipe(
      startWith(valueType),
      map((type) => {
        const types: OperatorType[] = type === 'String'
          ? OperatorTypes.map((type) => type)
          : ['equals', 'not-equals'];

        const currentOperator = this.getControl('operator');
        if (!types.includes(currentOperator.value)) {
          currentOperator.setValue('equals'); // Reset it
        }

        return types;
      }),
    );
    this.reset(model);
  }

  reset(value?: Partial<ScopeFilter & { key: string }>, options?: { onlySelf?: boolean; emitEvent?: boolean; }): void {
    super.reset(value, options);
    const valueType: ValueType = this.getValueType(value?.value);
    this.valueType.setValue(valueType, { emitEvent: true });
    this.isNew = !value;
  }

  private getValueType(value: any): ValueType {
    const firstValue = value instanceof Array ? value[0] : value;
    if (typeof firstValue === 'number') {
      return 'Number';
    } if (typeof firstValue === 'boolean') {
      return 'Boolean';
    }
    return 'String';
  }
}

export class ScopeForm extends BaseForm<Scope> {
  readonly filtersArray: LCFormArray<ScopeFilter & { key: string }, ScopeFilterForm>;

  constructor(model?: Partial<Scope>) {
    super({
      title: new FormControl(model?.title, [Validators.required]),
      description: new FormControl(model?.description, []),
      filters: new FormControl(model?.filters || {}, []),
    });

    this.filtersArray = new LCFormArray<ScopeFilter & { key: string }, ScopeFilterForm>([], (val) => new ScopeFilterForm(val));
    combineLatest([this.filtersArray.valueChanges, this.filtersArray.statusChanges]).subscribe(() => {
      if (this.filtersArray.valid) {
        const value = this.filtersArray.getValue();
        const filters = value.reduce((filters, value) => {
          filters[value.key] = { operator: value.operator, value: value.value };
          return filters;
        }, {} as { [key: string]: ScopeFilter });

        this.getControl('filters').setValue(filters);
        this.updateValueAndValidity();
      } else {
        this.getControl('filters').setErrors(this.filtersArray.errors);
      }
    });
    this.reset(model);
  }

  override reset(value?: Partial<Scope>, options?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined; } | undefined): void {
    super.reset(value, options);
    const filters = value?.filters || {};
    const filtersArr = Object.keys(filters).map((key) => {
      const value = filters[key];
      return { key, operator: value?.operator, value: value?.value };
    });
    this.filtersArray.reset(filtersArr);
  }

  // override getValue(options?: { removeNulls?: boolean | undefined; onlyDirty?: boolean | undefined; } | undefined) {

  // }
}

export class SurveyForm extends BaseForm<Survey> {
  readonly dynamicForm: DynamicForm;

  constructor(model?: Partial<Survey>) {
    super({
      _id: new FormControl(model?._id, []),
      active: new FormControl(model?.active ?? true, [Validators.required]),
      title: new FormControl(model?.title, [Validators.required]),
      description: new FormControl(model?.description, [Validators.required]),
      key: new FormControl(model?.key, [Validators.required]),
      form: new DynamicForm(model?.form),
      distinctOn: new FormControl(model?.distinctOn, [arrayLengthValidator(1)]),
      scopes: new LCFormArray(model?.scopes || [], (scope) => new ScopeForm(scope)),
    });
    this.dynamicForm = this.get('form') as DynamicForm;

    this.reset(model);
  }
}
