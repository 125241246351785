import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import {
  CardsModule, CheckboxModule, FormButtonsModule, HeaderModule,
} from '@lc/ui';

import { SurveyResponseListComponent } from './survey-response-list.component';

const angularModules = [
  CommonModule,
  MatTableModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatIconModule,
];

const lcModules = [
  CardsModule,
  CheckboxModule,
  FormButtonsModule,
  HeaderModule,
];

const externalComponents = [SurveyResponseListComponent];

@NgModule({
  declarations: [
    ...externalComponents,
  ],
  exports: [
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class SurveyResponseListModule { }
