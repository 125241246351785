import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { CardsModule, DynamicFormModule, FormButtonsModule, HeaderModule } from '@lc/ui';

// Checkout Components
import { SurveyResponseFormComponent } from './survey-response-form.component';

const angularModules = [CommonModule];
const lcModules = [CardsModule, HeaderModule, FormButtonsModule, DynamicFormModule, MatIconModule];
const externalComponents = [SurveyResponseFormComponent];

@NgModule({
  declarations: [...externalComponents],
  exports: [...externalComponents],
  imports: [...angularModules, ...lcModules],
})
export class SurveyResponseFormModule {}
