import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

import {
  CardsModule, CheckboxModule, ChipsModule, DropdownModule, DynamicFormModule, FormArrayComponent, FormButtonsModule, HeaderModule, HoveredDirective, TextboxModule,
} from '@lc/ui';

// Checkout Components
import { SurveyFormComponent } from './survey-form.component';

// Internal components
import { SurveyInputFieldComponent } from './survey-input-field';

const angularModules = [CommonModule, MatListModule, MatFormFieldModule, MatInputModule, MatLegacyButtonModule, MatIconModule];
const lcModules = [
  CheckboxModule, TextboxModule, DropdownModule,
  CardsModule, HeaderModule, FormButtonsModule,
  FormArrayComponent, DynamicFormModule, HoveredDirective,
  ChipsModule,
];
const externalComponents = [SurveyFormComponent];
const lcComponents = [SurveyInputFieldComponent];

@NgModule({
  declarations: [
    ...externalComponents,
    ...lcComponents,
  ],
  exports: [
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class SurveyFormModule { }
