import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveyResponse, SurveyResponseClient } from '../clients';

@Injectable({
  providedIn: 'root',
})
export class SurveyResponseService {
  constructor(private client: SurveyResponseClient) { }

  query$(filters?: { surveyKey?: string, submittedBy?: string }): Observable<SurveyResponse[]> {
    return this.client.query$({ filters });
  }

  async createResponse(surveyId: string, response: SurveyResponse): Promise<any> {
    return await this.client.create(surveyId, response);
  }
}
