export class SurveyStatistics {
  readonly _id?: string;

  survey!: string;

  counts!: { [inputKey: string]: { [value: string ]: any } };

  constructor(model?: Partial<SurveyStatistics>) {
    Object.assign(this, model);
    this.counts = this.counts || {};
  }
}
