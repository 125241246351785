import { Component } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ToasterService } from '@lc/core';
import { Survey } from '../../clients';
import { SurveyService } from '../../services';

@Component({
  selector: 'lc-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss'],
})
export class SurveyListComponent {
  readonly data = new MatTableDataSource<Survey>();
  readonly columns = ['active', 'title', 'description', 'edit'];
  readonly isLoading: { [surveyId: string]: boolean } = {};

  constructor(
    private surveyService: SurveyService,
    private toaster: ToasterService,
  ) {
    this.fetchSurveys();
  }

  private async fetchSurveys(): Promise<void> {
    try {
      this.data.data = await this.surveyService.query();
    } catch (error: any) {
      const { message } = error?.error?.error || {};
      this.toaster.showError(message || 'An unkown error occurred.');
    }
  }

  async toggleSurveyActive(survey: Survey) {
    this.isLoading[survey._id] = true;

    await this.surveyService.toggleSurveyActive(survey, survey.active)
      .then(() => {
        const message = `Survey "${survey.title}" is now ${survey.active ? 'active' : 'inactive'}`;
        this.toaster.showInfo(message);
        console.log(message);
      })
      .catch((error) => {
        const message = 'Error updating survey\'s active status';
        this.toaster.showError(message);
        console.error(message, error);
      })
      .finally(() => {
        // Reset loading state for the survey
        this.isLoading[survey._id] = false;
      });
  }
}
