import { Injectable } from '@angular/core';
import { ApiService } from '@lc/core';
import { Observable } from 'rxjs';
import { SurveyResponse } from './survey-response.model';

const ROUTES = {
  get: () => 'survey-responses',
  create: (id: string) => `surveys/${id}/responses`,
};

export interface SurveyResponseQueryOptions {
  filters?: {
    surveyKey?: string;
    submittedBy?: string;
  }
}

@Injectable({
  providedIn: 'root',
})
export class SurveyResponseClient {
  constructor(private apiService: ApiService) { }

  query$(options?: SurveyResponseQueryOptions): Observable<SurveyResponse[]> {
    const url = ROUTES.get();
    const params = options?.filters;
    return this.apiService.query$(url, { version: 'v2', model: SurveyResponse, params });
  }

  async create(surveyId: string, response: SurveyResponse) {
    const url = ROUTES.create(surveyId);
    return await this.apiService.create(url, response, { version: 'v2', model: SurveyResponse });
  }
}
