import { Injectable } from '@angular/core';
import { ApiService } from '@lc/core';
import { Observable } from 'rxjs';
import { SurveyForm } from './survey.form';
import { AvailableSurvey, Survey, ScopingData } from './survey.model';

const ROUTES = {
  get: () => 'surveys',
  getAvailable: () => 'surveys/available',
  getById: (id: string) => `surveys/${id}`,
  create: () => 'surveys',
  update: (id: string) => `surveys/${id}`,
  delete: (id: string) => `surveys/${id}`,
};

@Injectable({
  providedIn: 'root',
})
export class SurveyClient {
  constructor(private apiService: ApiService) { }

  async create(form: SurveyForm): Promise<Survey> {
    const url = ROUTES.create();
    const body = form.value;
    return await this.apiService.create(url, body, { version: 'v2', model: Survey });
  }

  async update(id: string, form: SurveyForm): Promise<Survey> {
    const url = ROUTES.update(id);
    const body = form.value;
    return await this.apiService.update(url, body, { version: 'v2', model: Survey });
  }

  async patch(id: string, form: SurveyForm): Promise<Survey> {
    const url = ROUTES.update(id);
    const body = form.getValue({ onlyDirty: true });
    return await this.apiService.patch(url, body, { version: 'v2', model: Survey });
  }

  query$(): Observable<Survey[]> {
    const url = ROUTES.get();
    return this.apiService.query$(url, { version: 'v2', model: Survey });
  }

  getById$(id: string): Observable<Survey> {
    const url = ROUTES.getById(id);
    return this.apiService.find$(url, { version: 'v2', model: Survey });
  }

  queryAvailable$(scoping: ScopingData): Observable<AvailableSurvey[]> {
    const url = ROUTES.getAvailable();
    return this.apiService.query$(url, { version: 'v2', model: AvailableSurvey, params: scoping });
  }
}
