<div class="fx-column">

  <div class="fx-row fx-1-1-0 x-end mb-4">
    <button mat-raised-button color="primary" [routerLink]="['create']">New survey</button>
  </div>

  <div class="mat-elevation-z1">
    <table class="w-100" mat-table [dataSource]="data" multiTemplateDataRows matSort
           matSortActive="name" matSortDirection="asc">

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell mat-hint" [attr.colspan]="columns.length">
          <span>No data matching the filter.</span>
        </td>
      </tr>

      <!-- active column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let vm">
          <ng-container *ngIf="isLoading[vm._id]; else checkboxTemplate">
            <div class="spinner-container">
              <mat-progress-spinner [diameter]="20" mode="indeterminate"></mat-progress-spinner>
            </div>
          </ng-container>
          <ng-template #checkboxTemplate>
            <lc-checkbox [(ngModel)]="vm.active" (toggled)="toggleSurveyActive(vm)"></lc-checkbox>
          </ng-template>
        </td>

      </ng-container>

      <!-- expand column -->
      <ng-container matColumnDef="expand" stickyEnd>
        <th mat-header-cell *matHeaderCellDef>
          <button mat-icon-button [disabled]="data.filteredData.length === 0"
                  [matTooltip]="isAllExpanded ? 'Collapse rows' : 'Expand rows'"
                  matTooltipPosition="above" (click)="onToggleExpandAll(!isAllExpanded)">
            <mat-icon>{{isAllExpanded ? 'expansions_up' : 'expansions_down'}}</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let vm">
          <button mat-icon-button [disabled]="data.filteredData.length === 0"
                  [matTooltip]="isAllExpanded ? 'Collapse rows' : 'Expand rows'"
                  matTooltipPosition="above" (click)="onToggleExpandAll(!isAllExpanded)">
            <mat-icon>{{isAllExpanded ? 'expansions_up' : 'expansions_down'}}</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- edit column -->
      <ng-container matColumnDef="edit" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let vm" (click)="$event.stopPropagation()">
          <button mat-icon-button [routerLink]="[vm._id]">
            <mat-icon class="material-icons-outlined">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- expanded element -->
      <ng-container matColumnDef="details">
        <td mat-cell *matCellDef="let vm">
          <div class="element-detail" [@detailExpand]="vm.isExpanded ? 'expanded' : 'collapsed'">
            Details
          </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Title</th>
        <td mat-cell *matCellDef="let vm">
          {{ vm.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Description</th>
        <td mat-cell *matCellDef="let vm">
          {{ vm.description }}
        </td>
      </ng-container>


      <ng-container matColumnDef="empty">
        <td mat-cell *matCellDef="let vm"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let vm; columns: columns" class="element-row"></tr>
    </table>
  </div>
</div>
