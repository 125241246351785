export class SurveyResponse {
  readonly _id?: string;

  survey!: string;

  /** The unique survey the response belongs to */
  surveyKey!: string;

  /** Identifier used to map a Survey Response to a specific entity */
  submittedBy?: string;

  /** Timestamp */
  createdAt?: Date;

  /** All response data collected from the survey form */
  response!: { [key: string]: any };

  /** Used to store various metadata */
  metadata?: any;

  constructor(model?: Partial<SurveyResponse>) {
    Object.assign(this, model);
    this.response = this.response || {};
  }
}
