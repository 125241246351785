<form [formGroup]="form">
  <lc-card [loading]="spinner" [header]="header">
    <ng-template #content>
      <lc-textbox label="Label" formControlName="label"></lc-textbox>
      <lc-dropdown label="Input Type" formControlName="inputType" [options]="form.inputOptions"
                   displayPath="display" valuePath="value"></lc-dropdown>
      <lc-textbox label="Key" formControlName="key"></lc-textbox>
      <lc-textbox label="Hint" formControlName="hint"></lc-textbox>
      <lc-textbox label="Placeholder" formControlName="placeholder"></lc-textbox>

      <lc-form-array *ngIf="form.hasOptions$ | async" label="Options" [form]="form.options">

        <ng-template #edit let-optionForm="form">
          <ng-container [formGroup]="optionForm">
            <lc-textbox label="Label" formControlName="label"></lc-textbox>

            <lc-dropdown [formControl]="optionForm.valueType" label="Value Type"
                         placeholder="ex: String, Boolean, Number"
                         [options]="optionForm.valueTypes"></lc-dropdown>

            <ng-container [ngSwitch]="optionForm.valueType$ | async">
              <lc-checkbox *ngSwitchCase="'Boolean'" label="Value"
                           formControlName="value"></lc-checkbox>

              <lc-textbox *ngSwitchCase="'Number'" formControlName="value" label="Value"
                          placeholder="i.e. 10.00" [inputMask]="MASKS.decimalWithWholeNumber"
                          [maskDropSpecialCharacters]="false"></lc-textbox>

              <lc-textbox *ngSwitchDefault label="Value" formControlName="value"></lc-textbox>

            </ng-container>
            <!-- <pre>{{optionForm.value | json}}</pre> -->
          </ng-container>
        </ng-template>

        <ng-template #view let-optionForm="form">
          <lc-header *ngIf="optionForm?.value as option" [header]="option?.label"
                     [subheader]="option?.value"></lc-header>
        </ng-template>

      </lc-form-array>

      <!-- <pre>{{form.options.value | json}}</pre>
      <pre>{{form.value | json}}</pre> -->
      <!-- <lc-textbox label="Order" formControlName="order"></lc-textbox> -->
      <!-- <lc-textbox label="Default Value" formControlName="defaultValue"></lc-textbox> -->
      <!-- <lc-textbox label="Validators" formControlName="validators"></lc-textbox> -->
      <!-- <lc-textbox label="Options" formControlName="options"></lc-textbox> -->
      <!-- <lc-textbox label="Group Key" formControlName="groupKey"></lc-textbox> -->
    </ng-template>

    <ng-template #actions>
      <lc-form-buttons (save)="onSave($event)" (cancel)="onCancel()"></lc-form-buttons>
    </ng-template>
  </lc-card>
</form>