import {
  Component, Inject, Input, Optional,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IDialogOptions, PromptDialogService, ToasterService, UserService } from '@lc/core';
import { DynamicForm, LoadingEvent } from '@lc/ui';
import { ScopingData, Survey } from '../../clients';
import { SurveyResponseService } from '../../services';

export class SurveyResponseFormConfig {
  survey!: Survey;
  scoping!: ScopingData;
}

@Component({
  selector: 'lc-survey-response-form',
  templateUrl: './survey-response-form.component.html',
  styleUrls: ['./survey-response-form.component.scss'],
})
export class SurveyResponseFormComponent {
  private static closedSurveys: string[] = [];

  @Input() config: SurveyResponseFormConfig;
  readonly spinner = LoadingEvent.none;
  readonly canClose: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<SurveyResponseFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) readonly data: SurveyResponseFormConfig,
    private surveyResponseService: SurveyResponseService,
    private toasterService: ToasterService,
    userService: UserService,
  ) {
    this.config = data;
    this.canClose = userService.isAssumedUser();
  }

  async onCancel() {
    SurveyResponseFormComponent.closedSurveys.push(this.config?.survey?._id);
    this.dialogRef?.close();
  }

  async onSave(form: DynamicForm) {
    this.spinner?.start('Saving...');

    // I'm getting information about each input from the form controls
    // but there may be a better way to accomplish this.
    const response = form.inputForm.value;

    const payload = {
      survey: this.config?.survey?._id,
      surveyKey: this.config?.survey?.key,
      createdAt: new Date(),
      metadata: this.config.scoping,
      response,
    };

    await this.surveyResponseService.createResponse(this.config?.survey?._id, payload)
      .then(() => this.toasterService.showInfo('Feedback submitted. Thank you!'))
      .catch((error) => {
        console.error('An error occurred submitting feedback', error);
        this.toasterService.showError('An error occurred submitting feedback');
      })
      .finally(() => {
        this.spinner?.done();
        this.dialogRef?.close();
      });
  }

  static async open(service: PromptDialogService, data?: SurveyResponseFormConfig, options?: IDialogOptions): Promise<any> {
    if (SurveyResponseFormComponent.closedSurveys.includes(data?.survey?._id!)) {
      return;
    }
    const defaultOptions: IDialogOptions = { width: '750px', disableClose: true, panelClass: 'p-0' };
    const opts: IDialogOptions = Object.assign(defaultOptions, options);
    return await service.openComponent(SurveyResponseFormComponent, data, opts);
  }
}
