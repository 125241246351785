import { DynamicFormModel } from '@lc/ui';
import { SurveyResponse } from './survey-response.model';

export const OperatorTypes = ['equals', 'in', 'not-in', 'not-equals'] as const;
export type OperatorType = typeof OperatorTypes[number];

export type ScopingData = { [key: string]: string | number | boolean | (string | number | boolean)[] };

export class ScopeFilter {
  readonly operator: OperatorType = 'equals';
  readonly value: any;

  constructor(model?: Partial<ScopeFilter>) {
    Object.assign(this, model);
  }
}

export class Scope {
  /** The title of this scope */
  readonly title!: string;

  /** The description of this scope */
  readonly description?: string;

  /** The data that filters this scope */
  readonly filters: { [key: string]: ScopeFilter } = {};

  constructor(model?: Partial<Scope>) {
    Object.assign(this, model);
  }
}

export class Survey {
  /** The key of this survey */
  readonly _id!: string;

  /** The title of this survey */
  readonly title!: string;

  /** The description of this survey */
  readonly description!: string;

  /** The unique key for this survey (different than the _id) */
  readonly key!: string;

  /** The type of inputs to display on the form */
  readonly form!: DynamicFormModel;

  readonly responses?: SurveyResponse[];

  /** An array of fieldNames that make this survey uniqueu (i.e. - ['userId', 'orderId']) */
  readonly distinctOn: string[];

  /** The scoping this survey applies to */
  readonly scopes: Scope[] = [];

  readonly active: boolean = true;

  constructor(model?: Partial<Survey>) {
    Object.assign(this, model);
    this.responses = (this.responses || []).map((response) => new SurveyResponse(response));
    this.distinctOn = model?.distinctOn || [];
    this.scopes = (this.scopes || []).map((scope) => new Scope(scope));
    this.active = model?.active ?? this.active;
  }
}

export class AvailableSurvey {
  /** The scope that was sent for this request */
  readonly scope!: ScopingData;

  /** The survey that applies to this scope */
  readonly survey!: Survey;

  constructor(model?: Partial<AvailableSurvey>) {
    Object.assign(this, model);
    this.survey = new Survey(model?.survey);
  }
}
